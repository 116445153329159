import { DateTime } from 'luxon';

import { i18n } from '@/i18n';

export function isToday(day) {
  const today = DateTime.local().startOf('day');

  return DateTime.isDateTime(day) && today.hasSame(day, 'day');
}

export function isTomorrow(day) {
  const tomorrow = DateTime.local().startOf('day').plus({ days: 1 });

  return DateTime.isDateTime(day) && tomorrow.hasSame(day, 'day');
}

export function isValidDate(date) {
  return date instanceof Date
    && !Number.isNaN(date.getTime());
}

export function monthYearFromISO(date) {
  return DateTime.fromISO(date)
    .setLocale(i18n.locale)
    .toFormat('LLLL yyyy');
}
